const baseConfig = {
  MeetingCode: "AM2024",
  MeetingName: "2024 Annual Meeting",
  CmePdfLink: "",
  CmeOnly: false,
  LoginUsingRegCode: true,
  IsCmeSingleDay: false,
  Sponsor: {
    IsActive: true,
    Url: "",
    // Need to use require on image urls for webpack to pick up in compile time
    ImageUrl: require("@/assets/sponsorAM2024.png"),
    ImageAltText: "Sponsor Image - Sanofi and Regeneron",
    BoothNumber: "",
  },
  Testers: [
    0, 1, 2, 602366, 602533, 602534, 602535, 602536, 602537, 602182, 602183,
    602184, 602188, 602189, 602190, 602192, 602181, 602180, 602179, 602178,
    602229, 602230, 602265, 602266, 602267, 602268, 602269, 602270, 602271,
    602272, 602273, 602274, 602275, 602276, 602277, 602278, 602279, 602264,
    602263, 602231, 602232, 602233, 602236, 602238, 602239, 602240, 602248,
    602249, 602250, 602252, 602253, 602254, 602255, 602256, 602257, 602258,
    602262,
  ], // Reg codes for the users testing, shows test sessions;
};

export default baseConfig;
